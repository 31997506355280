<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="text-dark mb-0"> {{ attendanceLanguage[language].daily_staff_list_and_employee_attendance }} </h3>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-xl-3 text-dark py-1">
                            <button class="btn btn-info btn-block mb-2" data-toggle="collapse" data-target="#list_info"> {{ attendanceLanguage[language].list_info }} &nbsp; <i class="fa fa-arrow-down"></i> </button>
                            <div class="collapse mb-3" id="list_info">
                                <label class="mt-2 px-2 col-sm-12 multi-language"> {{ attendanceLanguage[language].list_id }}: 
                                    <span class="ms-5" v-if="dsl_list.st_id">
                                        {{ dsl_list.datetime_list ? new Date(dsl_list.datetime_list).toISOString().split('T')[0] : '' }}
                                        {{ dsl_list.datetime_list ? new Date(dsl_list.datetime_list).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                                    </span> 
                                </label>
                                <input type="number" readonly class="form-control text-center" v-model="dsl_list.dsl_id">

                                <label class="mt-2 px-2 col-sm-12 multi-language"> {{ attendanceLanguage[language].work_date }}: </label>
                                <input type="date" class="form-control text-center" v-if="user.role != 'E'" v-model="dsl_list.work_date">
                                <input type="date" class="form-control text-center" v-else :min="startDate" :max="endDate" v-model="dsl_list.work_date">

                                <div class="row mt-2 float-left col-sm-12" >
                                    <label class="mt-2 px-2 col-sm-9 multi-language" id="work_date"> 
                                        <span> {{ attendanceLanguage[language].staff }}: </span> 
                                    </label>
                                    <span class="col-2" style="height: 40px;">
                                        <v-checkbox v-if="user.role == 'A'" class="custom-checkbox-label mt-0" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                                    </span>
                                </div>
                                <!-- <label class="mt-2 px-2 col-sm-12 multi-language" id="work_date"> 
                                    <span> {{ attendanceLanguage[language].staff }}: </span> 
                                </label> -->

                                <select class="form-control text-center" id="staff" v-model="dsl_list.st_id">
                                    <option value=""></option>
                                    <option v-for="staff in staffs.filter(obj => {
                                            return user.role != 'A' ? true : (obj.special_staff == showSpecialStaffs)
                                        })" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                                </select>
                                
                                <label class="mt-2 px-2 multi-language col-sm-10"> {{ attendanceLanguage[language].location }} : </label>
                                <button class="btn btn-success btn-sm col-sm-2" v-on:click="dsl_list.location.push('')" v-if="is_edit"> <i class="fa fa-plus"></i> </button>
                                <div class="row mt-2 float-left col-sm-12" v-for="(single_location, index) in dsl_list.location" :key="index">
                                    <input type="text" 
                                            class="form-control text-center col-sm-10 mb-1 locations"
                                            v-model="dsl_list.location[index]"
                                            @change="update_list_location(dsl_list.location[index], index)"
                                            v-bind:readonly="(dsl_list.location.length == 1 && employees.length > 0) || is_edit == false"
                                            >
                                    <button class="btn btn-danger btn-sm mb-1 mx-1" v-on:click="delete_location(dsl_list.location[index])" v-if="dsl_list.location.length > 1 && is_edit"> <i class="fa fa-minus"></i> </button>
                                </div>
                                <br><br><br><br>
                                <label class="px-2 multi-language col-sm-12"> {{ attendanceLanguage[language].note }}: </label>
                                <textarea cols="30" rows="5" class="form-control mb-3" style="resize: none;" v-model="dsl_list.note"></textarea>
                                
                                <label class="mt-2 px-2 col-sm-12 multi-language"> {{ attendanceLanguage[language].foods }}: </label>
                                <input type="number" class="form-control mb-3 text-center" v-model="dsl_list.food_number" v-bind:disabled="!is_edit || dsl_list.dsl_id == null" @change="set_food_number()">
                                <select class="form-control mb-3 text-center" v-model="dsl_list.food_group" v-bind:disabled="!is_edit || dsl_list.dsl_id == null" @change="set_food_group()">
                                    <option value="barzayakan">بەرزاییەکان</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                </select>

                                <div class="col-xl-12 mb-1" v-if="dsl_list.dsl_id == null"> <button class="btn btn-success btn-block" v-on:click="add_list()" id="btn-add"> {{ attendanceLanguage[language].add_list }} &nbsp; <i class="fa fa-plus"></i> </button> </div>
                                <div class="col-xl-12 mb-1" v-if="dsl_list.dsl_id != null"> <button class="btn btn-warning btn-block" id="btn_update" v-on:click="update_list()"> {{ attendanceLanguage[language].save_changes }} &nbsp; <i class="fa fa-save"></i> </button> </div>
                                <div class="col-xl-12 mb-1"> <button class="btn btn-info btn-block" id="btn_clear" v-on:click="clear_list()"> {{ attendanceLanguage[language].clear }} &nbsp; <i class="fa fa-trash"></i> </button> </div>
                            </div>

                            <button class="btn btn-danger btn-block mb-2" data-toggle="collapse" data-target="#missed_employees" v-if="user.role == 'A'"> {{ attendanceLanguage[language].missed_employees }} &nbsp; <i class="fa fa-arrow-down"></i> </button>
                            <div class="collapse px-2" id="missed_employees">
                                <button class="btn btn-primary btn-block" v-for="emp in missed_employees" :key="emp.emp_id" v-bind:id="'missed' + emp.emp_id" v-on:click="add_missed_to_attendance(emp.emp_id, 'missed' + emp.emp_id)"> {{ emp.full_name }} </button>
                            </div>
                        </div>
                        <div class="col-xl-9" style="overflow-x: auto;">
                            <table class="table table-bordered table-striped text-center text-dark">
                                <thead>
                                    <th style="width: 23%;"> {{ attendanceLanguage[language].employee }} </th>
                                    <th style="width: 10%;"> {{ attendanceLanguage[language].overtime }} </th>
                                    <th style="width: 7%;"> {{ attendanceLanguage[language].worked_hours }} </th>
                                    <th style="width: 15%;" v-if="is_edit"> {{ attendanceLanguage[language].status }} </th>
                                    <th style="width: 15%;"> {{ attendanceLanguage[language].location }} </th>
                                    <th style="width: 15%;"> Work </th>

                                    <th style="width: 10%;" v-if="is_edit"> {{ attendanceLanguage[language].actions }} </th>
                                </thead>
                                <tbody>
                                    <tr v-for="employee in employees" :key="employee.at_id">
                                        <td v-bind:class="{ 'bg-success text-white': employee.main_st_id != dsl_list.st_id }"> 
                                            {{ employee.full_name }} 
                                            <hr v-if="employee.main_st_id != dsl_list.st_id">
                                            <small v-if="employee.main_st_id != dsl_list.st_id"> {{ other.staffs.filter(obj => obj.st_id == employee.main_st_id)[0].staff_name }} </small>
                                        </td>
                                        <td> <input type="number" min="0" style="padding: 0 !important;" v-model.lazy="employee.overtime" v-bind:readonly="is_edit == false || employee.absent == 1" @change="set_overtime(employee.overtime, employee.at_id)" class="form-control text-center"> </td>
                                        <td> <input type="number" min="0" v-model.lazy="employee.worked_hours" v-bind:readonly="is_edit == false || employee.absent == 1 || employee.absent == 2" @change="set_worked_hours(employee.worked_hours, employee.at_id)" class="form-control text-center"> </td>
                                        <td v-if="is_edit"> 
                                            <button v-if="!isFriday" class="btn btn-danger btn-block mx-1 mb-1" v-bind:disabled="employee.absent == 1" v-on:click="set_to_absent(employee.at_id)"> {{ attendanceLanguage[language].absent }} </button>
                                            <button v-if="!isFriday" class="btn btn-success btn-block mx-1 mb-1" v-bind:disabled="employee.absent == 0" v-on:click="set_to_present(employee.at_id)"> {{ attendanceLanguage[language].present }} </button>
                                            <button v-if="isFriday" class="btn btn-warning btn-block mx-1 mb-1" v-bind:disabled="employee.absent == 2" v-on:click="set_to_off(employee.at_id)"> {{ attendanceLanguage[language].off }} </button>
                                        </td>
                                        <td> 
                                            <button class="btn m-1"
                                                    v-for="(single_location, index) in dsl_list.location.filter(obj => obj.length > 0)"
                                                    :key="index"
                                                    v-bind:disabled="single_location == employee.location || is_edit == false"
                                                    v-on:click="set_location(single_location, employee.at_id)"
                                                    v-bind:class="{ 'btn-secondary': single_location != employee.location, 'btn-success': single_location == employee.location }"> {{ single_location }} </button>
                                        </td>
                                        <td> <input type="text" v-model.lazy="employee.work" style="padding: 0 !important;" @change="setWork(employee.work, employee.at_id)" class="form-control text-center"> </td>
                                        <td v-if="is_edit">
                                            <button class="btn btn-primary mx-1 mb-1" :disabled="employee.absent == '1'" v-on:click="get_attendance(employee.at_id)" data-toggle="modal" data-target="#mdl_fine"> <i class="fa fa-edit"></i> </button>
                                            <button class="btn btn-danger mx-1 mb-1" v-if="employee.main_st_id != dsl_list.st_id" v-on:click="return_employee(employee.at_id, employee.main_st_id)"> <i class="fa fa-trash"></i> </button>
                                            <!-- <button class="btn btn-success mx-1 mb-1" v-else v-on:click="get_employee_staff(employee.at_id)" data-toggle="modal" data-target="#mdl_change_staff"> <i class="fa fa-paper-plane"></i> </button> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <change-pass/>

        <div class="modal animate__animated animate__fadeInDown animate__faster" id="mdl_fine">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_single_employee" data-dismiss="modal"> &times; </button>
                        <h5 class="text-dark mt-2"> Information </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid text-dark">
                            <!-- <div class="row mb-2">
                                <label class="col-sm-4"> {{ attendanceLanguage[language].fine }}: &nbsp; ({{ single_employee.salary_type ? (single_employee.salary_type == 'Daily' ? 'IQD.' : '$') : '' }}) </label>
                                <input type="number" min="0" @keypress="validateNumber($event)" v-model="single_employee.fine" class="form-control text-center col-sm-8">
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-4"> {{ attendanceLanguage[language].fine_reason }}:  </label>
                                <textarea rows="5" class="form-control col-sm-8" v-model="single_employee.fine_reason" style="resize: none; text-align: right;direction: rtl;"></textarea>
                            </div>
                            <div class="row mb-2">
                                <div class="alert alert-warning w-100 text-center"> Warning: minus not allowed in Fine. </div>
                            </div>
                            <hr> -->
                            <div class="row mb-2">
                                <label class="col-sm-4"> Transport: &nbsp; ({{ single_employee.salary_type ? (single_employee.salary_type == 'Daily' ? 'IQD.' : '$') : '' }}) </label>
                                <input type="number" min="0" v-model="single_employee.transport" class="form-control text-center col-sm-8">
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-4"> Transport Reason:  </label>
                                <textarea rows="5" class="form-control col-sm-8" v-model="single_employee.transport_reason" style="resize: none; text-align: right;direction: rtl;"></textarea>
                            </div>
                            <div class="row mb-2">
                                    <label class="col-sm-4"> Food: &nbsp; ({{ single_employee.salary_type ? (single_employee.salary_type == 'Daily' ? 'IQD.' : '$') : '' }}) </label>
                                    <input type="number" min="0" v-model="single_employee.food" class="form-control text-center col-sm-8">
                                </div>
                            <div class="row mb-2">
                                <label class="col-sm-4"> Food Reason:  </label>
                                <textarea rows="5" class="form-control col-sm-8" v-model="single_employee.food_reason" style="resize: none; text-align: right;direction: rtl;"></textarea>
                            </div>
                            <hr>
                            
                            <template v-if="user.role == 'A'">
                                <div class="row mb-2">
                                    <label class="col-sm-4"> Expense: &nbsp; ({{ single_employee.salary_type ? (single_employee.salary_type == 'Daily' ? 'IQD.' : '$') : '' }}) </label>
                                    <input type="number" min="0" v-model="single_employee.expense" class="form-control text-center col-sm-8">
                                </div>
                                <div class="row mb-2">
                                    <label class="col-sm-4"> Expense Reason:  </label>
                                    <textarea rows="5" class="form-control col-sm-8" v-model="single_employee.expense_reason" style="resize: none; text-align: right;direction: rtl;"></textarea>
                                </div>
                                <hr>
                                <div class="row mb-2">
                                    <label class="col-sm-4"> Loan: &nbsp; ({{ single_employee.salary_type ? (single_employee.salary_type == 'Daily' ? 'IQD.' : '$') : '' }}) </label>
                                    <input type="number" min="0" v-model="single_employee.loan" class="form-control text-center col-sm-8">
                                </div>
                                <div class="row mb-2">
                                    <label class="col-sm-4"> Loan Reason:  </label>
                                    <textarea rows="5" class="form-control col-sm-8" v-model="single_employee.loan_reason" style="resize: none; text-align: right;direction: rtl;"></textarea>
                                </div>
                                <hr>
                                <div class="row mb-2">
                                    <label class="col-sm-4"> Accomodation: &nbsp; ({{ single_employee.salary_type ? (single_employee.salary_type == 'Daily' ? 'IQD.' : '$') : '' }}) </label>
                                    <input type="number" min="0" v-model="single_employee.accomodation" class="form-control text-center col-sm-8">
                                </div>
                                <div class="row mb-2">
                                    <label class="col-sm-4"> Accomodation Reason:  </label>
                                    <textarea rows="5" class="form-control col-sm-8" v-model="single_employee.accomodation_reason" style="resize: none; text-align: right;direction: rtl;"></textarea>
                                </div>
                                <hr>
                            </template>
                            <div class="row mb-2">
                                <button class="btn btn-primary btn-block" id="btn_update_attendance" v-on:click="update_attendance()"> {{ attendanceLanguage[language].save }} &nbsp; <i class="fa fa-save"></i> </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal animate__animated animate__fadeInDown animate__faster" id="mdl_change_staff">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_single_employee_staff" data-dismiss="modal"> &times; </button>
                        <h5 class="text-dark mt-2"> {{ attendanceLanguage[language].send_employee_to_another_staff }} </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid text-dark">
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-1"> {{ attendanceLanguage[language].new_staff }}: </label>
                                <select class="form-control col-sm-9" v-model="single_employee_staff.new_st_id">
                                    <option value=""></option>
                                    <option v-for="staff in filtered_other_staffs" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>  
                                </select>
                            </div>
                            <button class="btn btn-success btn-block" v-on:click="send_employee()"> {{ attendanceLanguage[language].send }} &nbsp; <i class="fa fa-paper-plane"></i> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import auth from '../auth.js';
    import $ from 'jquery';
    import { Bus } from '../Bus.js';
    import attendanceLanguage from '../languages/attendance.js';
    import showError from '../showError.js';
    const nullValues = ["", null];
    export default {
        name: "Attendance",
        data() {
            return {
                employees: [],
                missed_employees: [],
                staffs: [],
                single_employee: {
                    at_id: null,
                    fine: 0,
                    fine_reason: "",
                    expense: 0,
                    expense_reason: "",
                    transport: 0,
                    transport_reason: "",
                    food: 0,
                    food_reason: "",
                    loan: 0,
                    loan_reason: "",
                    accomodation: 0,
                    accomodation_reason: "",
                },
                single_employee_staff: {
                    at_id: null,
                    new_st_id: null
                },
                dsl_list: {
                    dsl_id: null,
                    st_id: null,
                    work_date: new Date().toISOString().split("T")[0],
                    location: [""],
                    note: "",
                    food_number: 0,
                    food_group: null,
                    datetime_list: null
                },
                other: {
                    staffs: []
                },
                user: {
                    username: "",
                    role: "",
                    en_id: null
                },
                attendanceLanguage,
                language: "English",
                showSpecialStaffs: "false"
            }
        },
        beforeCreate() {
            auth("attendance");
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user.username = data.username;
                this.user.role = data.type;
                this.user.en_id = data.en_id || null;

                if(this.user.en_id != null){
                    this.$http.post("/staff/getByEngineer/" + this.user.en_id).then(({data}) => {
                        this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
                    });
                } else {
                    this.$http.post("/staff/getData").then(({data}) => {
                        this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
                    });
                }
            });
            
            this.$http.post("/staff/getData").then(({data}) => {
                this.other.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
            });
        },
        mounted() {
            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl")
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr")
                }
            });
        },
        methods: {
            get_attendance(at_id) {
                const [attendance] = this.employees.filter(obj => obj.at_id == at_id);
                this.single_employee.at_id = at_id;
                this.single_employee.fine = attendance.fine;
                this.single_employee.fine_reason = attendance.fine_reason;
                this.single_employee.expense = attendance.expense;
                this.single_employee.expense_reason = attendance.expense_reason;
                this.single_employee.transport = attendance.transport;
                this.single_employee.transport_reason = attendance.transport_reason;
                this.single_employee.food = attendance.food;
                this.single_employee.food_reason = attendance.food_reason;
                this.single_employee.loan = attendance.loan;
                this.single_employee.loan_reason = attendance.loan_reason;
                this.single_employee.accomodation = attendance.accomodation;
                this.single_employee.accomodation_reason = attendance.accomodation_reason;
                this.single_employee.salary_type = attendance.salary_type;
            },
            get_list(st_id, work_date) {
                this.$http.post("/daily_staff_list/getListAndAttendance", {
                    st_id,
                    work_date
                }).then(({data}) => {
                    this.employees = data.employees.sort((a, b) => a.sort_code - b.sort_code)
                    if(data.dsl_list != null){
                        this.dsl_list.dsl_id = data.dsl_list.dsl_id;
                        let loc =  data.dsl_list.location;
                        this.dsl_list.location = loc.split(',');
                        this.dsl_list.note = data.dsl_list.note;
                        this.dsl_list.food_number = data.dsl_list.food_number;
                        this.dsl_list.food_group = data.dsl_list.food_group;
                        this.dsl_list.datetime_list = data.dsl_list.datetime_list;
                    } else {
                        this.dsl_list.dsl_id = null;
                        this.dsl_list.location = [""];
                        this.dsl_list.note = "";
                        this.dsl_list.food_number = 0;
                        this.dsl_list.food_group = null;
                        this.dsl_list.datetime_list = null;
                    }
                }).catch((err) => {
                    showError(err);
                });
            },
            get_missed_employees() {
                this.$http.post("/attendance/getMissedEmployees", {
                    st_id: this.dsl_list.st_id,
                    work_date: this.dsl_list.work_date
                }).then(({data}) => {
                    this.missed_employees = data;
                });
            },
            clear_list() {
                this.dsl_list.dsl_id = null;
                this.dsl_list.st_id = null;
                this.dsl_list.work_date = new Date().toISOString().split("T")[0];
                this.dsl_list.location = [""];
                this.dsl_list.note = "";
                this.employees = [];
                this.dsl_list.food_number = 0;
                this.dsl_list.food_group = null;
                this.dsl_list.datetime_list = null;
            },
            clear_single_employee () {
                this.single_employee.at_id = null;
                this.single_employee.fine= 0;
                this.single_employee.fine_reason= "";
                this.single_employee.expense= 0;
                this.single_employee.expense_reason= "";
                this.single_employee.transport= 0;
                this.single_employee.transport_reason= "";
                this.single_employee.food= 0;
                this.single_employee.food_reason= "";
                this.single_employee.loan= 0;
                this.single_employee.loan_reason= "";
                this.single_employee.accomodation= 0;
                this.single_employee.accomodation_reason= "";
            },
            clear_single_employee_staff() {
                this.single_employee_staff.at_id = null;
                this.single_employee_staff.new_st_id = null;
            },
            async add_list() {
                this.dsl_list.location = this.dsl_list.location.filter(obj => obj.length > 0);
                if(!nullValues.includes(this.dsl_list.st_id) && !nullValues.includes(this.dsl_list.work_date) && !nullValues.includes(this.dsl_list.st_id) && !this.dsl_list.location.includes("")){
                    this.dsl_list.location = this.dsl_list.location.filter(obj => obj.length > 0)
                    document.getElementById('btn-add').setAttribute('disabled', 'disabled')
                    var d = new Date()
                    d.setHours(d.getHours() + 3)

                    if(this.user.role != 'A' && new Date(this.dsl_list.work_date).toISOString().split('T')[0] > new Date().toISOString().split('T')[0]){
                        this.$alert("You can't add list for future date", "Error", "error");
                        document.getElementById('btn-add').removeAttribute('disabled')
                        return
                    }

                    var c = null;
                    if(this.user.role == 'A' && !this.isFriday) {
                        c = confirm("Are you sure you want to add this list with Present?");
                    }
                    await this.$http.post("/daily_staff_list/addList", {
                        st_id: this.dsl_list.st_id,
                        work_date: this.dsl_list.work_date,
                        user: this.user.username,
                        location: this.dsl_list.location.join(","),
                        note: this.dsl_list.note || null,
                        datetime_list: d,
                        datetime_log: d,
                        absent:this.isFriday ? '2' : (this.user.role == 'E' ? '0' : this.user.role == 'A' && c ? '0' : '1'),
                    }).then(({data}) => {
                        document.getElementById('btn-add').removeAttribute('disabled')
                        this.dsl_list.dsl_id = data.dsl_id;
                        this.dsl_list.datetime_list = data.datetime_list;
                        this.employees = data.employees;
                        this.other.staffs.push({
                            dsl_id: data.dsl_id,
                            st_id: this.dsl_list.st_id,
                            staff_name: this.staffs.filter(obj => obj.st_id == this.dsl_list.st_id)[0].staff_name
                        });
                        this.missed_employees = [];
                        this.$alert("List Created", "Successfull", "success");
                    }).catch((err) => {
                        showError(err);
                    }).finally(() => {
                        document.getElementById('btn-add').removeAttribute('disabled')
                        document.getElementById('btn-update').removeAttribute('disabled')
                    });
                    
                } else {
                    this.$alert("Fill all required fields", "Empty Field", "warning");
                }
            },
            add_missed_to_attendance(emp_id, btn_id){
                if(this.dsl_list.dsl_id != null){
                    this.$confirm("Are you sure add this employee ?", "", "question").then(() => {
                        var c = null;
                        if(this.user.role == 'A' && !this.isFriday) {
                            c = confirm("Are you sure you want to add this employee with Present?");
                        }
                        document.getElementById(btn_id).setAttribute("disabled", "disabled");
                        var d = new Date()
                        d.setHours(d.getHours() + 3)
                        this.$http.post("/attendance/addAttendance", {
                            emp_id,
                            dsl_id: this.dsl_list.dsl_id,
                            st_id: this.dsl_list.st_id,
                            location: this.dsl_list.location[0] || "",
                            user: this.user.username,
                            employee: this.missed_employees.find(obj => obj.emp_id == emp_id).full_name,
                            datetime_log: d,
                            datetime_list: d,
                            absent: this.isFriday ? '2' : (this.user.role == 'E' ? '0' : this.user.role == 'A' && c ? '0' : '1'),
                        }).then(({data}) => {
                            this.employees.push({
                                at_id: data.at_id,
                                emp_id,
                                full_name: this.missed_employees.filter(obj => obj.emp_id)[0].full_name,
                                main_st_id: this.dsl_list.st_id,
                                st_id: this.dsl_list.st_id,
                                salary_type: this.missed_employees.filter(obj => obj.emp_id)[0].salary_type,
                                overtime: 0,
                                worked_hours: this.isFriday ? 0 : (this.user.role == 'E' ? 8 : this.user.role == 'A' && c ? 8 : 0),
                                fine: 0,
                                fine_reason: null,
                                expense: 0,
                                expense_reason: null,
                                transport: 0,
                                transport_reason: null,
                                food: 0,
                                food_reason: null,
                                loan: 0,
                                loan_reason: null,
                                accomodation: 0,
                                accomodation_reason: null,
                                absent:this.isFriday ? '2' : (this.user.role == 'E' ? '0' : this.user.role == 'A' && c ? '0' : '1'),
                                location: this.dsl_list.location[0] || ""
                            });
                            this.missed_employees = this.missed_employees.filter(obj => obj.emp_id != emp_id);
                        }).catch(() => {
                            document.getElementById(btn_id).removeAttribute("disabled");
                        });
                    });
                }
            },
            set_to_absent(at_id) {
                this.$confirm("Are you sure this employee is absent ?", "", "question").then(() => {
                    var d = new Date()
                    d.setHours(d.getHours() + 3)
                    this.$http.patch("/attendance/setAbsent/" + at_id, {
                        dsl_id: this.dsl_list.dsl_id,
                        user: this.user.username,
                        employee: this.employees.filter(obj => obj.at_id == at_id)[0].full_name,
                        datetime_log: d,
                        st_id: this.dsl_list.st_id,
                        datetime_list: d
                    }).then(() => {
                        let index = this.employees.findIndex(obj => obj.at_id == at_id);
                        this.employees[index].absent = "1";
                        this.employees[index].worked_hours = 0;
                        this.employees[index].overtime = 0;
                        this.employees[index].fine = 0;
                        this.employees[index].fine_reason = null;
                        this.employees[index].location = '';
                        this.employees[index].expense = 0;
                        this.employees[index].expense_reason = null;
                        this.employees[index].transport = 0;
                        this.employees[index].transport_reason = null;
                        this.employees[index].food = 0;
                        this.employees[index].food_reason = null;
                        this.employees[index].loan = 0;
                        this.employees[index].loan_reason = null;
                        this.employees[index].accomodation = 0;
                        this.employees[index].accomodation_reason = null;
                        this.$alert("Employee absented", "Successfull", "success");
                    });
                });
            },
            set_to_present(at_id) {
                this.$confirm("Are you sure this employee is present ?", "", "question").then(() => {
                    var d = new Date()
                    d.setHours(d.getHours() + 3)
                    this.$http.patch("/attendance/cancelAbsent/" + at_id, {
                        dsl_id: this.dsl_list.dsl_id,
                        user: this.user.username,
                        employee: this.employees.filter(obj => obj.at_id == at_id)[0].full_name,
                        datetime_log: d,
                        st_id: this.dsl_list.st_id,
                        datetime_list: d
                    }).then(() => {
                        let index = this.employees.findIndex(obj => obj.at_id == at_id);
                        this.employees[index].absent = "0";
                        this.employees[index].worked_hours = 8;
                        this.$alert("Employee presented", "Successfull", "success");
                    });
                });
            },
            set_to_off(at_id) {
                this.$confirm("Are you sure this employee is iff ?", "", "question").then(() => {
                    var d = new Date()
                    d.setHours(d.getHours() + 3)
                    this.$http.patch("/attendance/setOff/" + at_id, {
                        dsl_id: this.dsl_list.dsl_id,
                        user: this.user.username,
                        employee: this.employees.filter(obj => obj.at_id == at_id)[0].full_name,
                        datetime_log: d,
                        st_id: this.dsl_list.st_id,
                        datetime_list: d
                    }).then(() => {
                        let index = this.employees.findIndex(obj => obj.at_id == at_id);
                        this.employees[index].absent = "2";
                        this.employees[index].worked_hours = 0;
                        this.employees[index].overtime = 0;
                        this.$alert("Employee set to Off", "Successfull", "success");
                    });
                });
            },
            set_worked_hours(worked_hours, at_id) {
                if(this.is_edit){
                    var d = new Date()
                    d.setHours(d.getHours() + 3)
                    this.$http.patch("/attendance/setWorkedHours/" + at_id, {
                        worked_hours,
                        dsl_id: this.dsl_list.dsl_id,
                        user: this.user.username,
                        st_id: this.dsl_list.st_id,
                        datetime_log: d,
                        employee: this.employees.find(obj => obj.at_id == at_id).full_name,
                        datetime_list: d
                    }).then(() => {})
                    .catch((err) => { showError(err); });
                }
            },
            set_overtime(overtime, at_id) {
                if(this.is_edit){
                    var d = new Date()
                    d.setHours(d.getHours() + 3)
                    this.$http.patch("/attendance/setOvertime/" + at_id, {
                        overtime,
                        dsl_id: this.dsl_list.dsl_id,
                        user: this.user.username,
                        st_id: this.dsl_list.st_id,
                        datetime_log: d,
                        employee: this.employees.find(obj => obj.at_id == at_id).full_name,
                        datetime_list: d
                    }).then(() => {})
                    .catch((err) => { showError(err); });
                }
            },
            set_location(location, at_id) {
                var d = new Date()
                d.setHours(d.getHours() + 3)
                this.$http.patch("/attendance/setLocation/" + at_id, {
                    location,
                    dsl_id: this.dsl_list.dsl_id,
                    user: this.user.username,
                    st_id: this.dsl_list.st_id,
                    datetime_log: d,
                    employee: this.employees.find(obj => obj.at_id == at_id).full_name,
                    datetime_list: d
                }).then(() => {
                    let index = this.employees.findIndex(obj => obj.at_id == at_id);
                    this.employees[index].location = location;
                });
            },
            set_food_number() {
                document.getElementById("work_date").setAttribute('disabled', 'disabled');
                document.getElementById("staff").setAttribute('disabled', 'disabled');
                document.getElementById("btn_update").setAttribute('disabled', 'disabled');
                document.getElementById("btn_clear").setAttribute('disabled', 'disabled');
                
                var d = new Date()
                d.setHours(d.getHours() + 3)
                
                this.$http.patch("/daily_staff_list/setFoodNumber/" + this.dsl_list.dsl_id, {
                    food_number: this.dsl_list.food_number,
                    st_id: this.dsl_list.st_id,
                    user: this.user.username,
                    datetime_food: d,
                    datetime_log: d
                }).finally(() => {
                    document.getElementById("work_date").removeAttribute('disabled');
                    document.getElementById("staff").removeAttribute('disabled');
                    document.getElementById("btn_update").removeAttribute('disabled');
                    document.getElementById("btn_clear").removeAttribute('disabled');
                });
            },
            set_food_group() {
                document.getElementById("work_date").setAttribute('disabled', 'disabled');
                document.getElementById("staff").setAttribute('disabled', 'disabled');
                document.getElementById("btn_update").setAttribute('disabled', 'disabled');
                document.getElementById("btn_clear").setAttribute('disabled', 'disabled');

                var d = new Date()
                d.setHours(d.getHours() + 3)
                
                this.$http.patch("/daily_staff_list/setFoodGroup/" + this.dsl_list.dsl_id, {
                    food_group: this.dsl_list.food_group,
                    st_id: this.dsl_list.st_id,
                    user: this.user.username,
                    datetime_food: d,
                    datetime_log: d
                }).finally(() => {
                    document.getElementById("work_date").removeAttribute('disabled');
                    document.getElementById("staff").removeAttribute('disabled');
                    document.getElementById("btn_update").removeAttribute('disabled');
                    document.getElementById("btn_clear").removeAttribute('disabled');
                });
            },
            get_employee_staff(at_id) {
                this.single_employee_staff.at_id = at_id;
            },
            send_employee() {
                if(!nullValues.includes(this.single_employee_staff.new_st_id)){
                    this.$confirm("Are you sure send this employee to choosed staff ? ", "", "question").then(() => {
                        const [employee] = this.employees.filter(obj => obj.at_id == this.single_employee_staff.at_id);
                        this.$http.patch("/attendance/changeStaff/" + this.single_employee_staff.at_id + "/" + this.single_employee_staff.new_st_id + "/" + this.dsl_list.work_date + "/" + this.dsl_list.st_id, {
                            emp_id: employee.emp_id,
                            overtime: employee.overtime,
                            worked_hours: employee.worked_hours,
                            fine: employee.fine,
                            fine_reason: employee.fine_reason,
                            expense: employee.expense,
                            expense_reason: employee.expense_reason,
                            transport: employee.transport,
                            transport_reason: employee.transport_reason,
                            food: employee.food,
                            food_reason: employee.food_reason,
                            loan: employee.loan,
                            loan_reason: employee.loan_reason,
                            accomodation: employee.accomodation,
                            accomodation_reason: employee.accomodation_reason,
                            absent: employee.absent,
                            work_date: this.dsl_list.work_date
                        }).then(() => {
                            this.$alert("Employee sended", "Success", "success");
                            this.employees = this.employees.filter(obj => obj.at_id != this.single_employee_staff.at_id);
                        }); 
                    });
                } else {
                    this.$alert("Select new staff", "Empty staff", "warning");
                }
            },
            return_employee(at_id, st_id) {
                this.$confirm("Are you sure return this employee to own staff ?", "", "question").then(() => {
                    this.$http.patch("/attendance/returnEmployee/" + at_id + "/" + st_id, {
                        work_date: this.dsl_list.work_date
                    }).then(() => {
                        this.employees = this.employees.filter(obj => obj.at_id != at_id);
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            update_list() {
                if(this.dsl_list.dsl_id != null){
                    if(!nullValues.includes(this.dsl_list.location)){
                        this.$confirm("Are you sure update the list informations ? ", "", "question").then(() => {
                            this.dsl_list.location = this.dsl_list.location.filter(obj => obj.length > 0);
                            var d = new Date()
                            d.setHours(d.getHours() + 3);
                            this.$http.patch("/daily_staff_list/updateList/" + this.dsl_list.dsl_id, {
                                location: this.dsl_list.location.join(","),
                                note: this.dsl_list.note ||  null,
                                st_id: this.dsl_list.st_id,
                                user: this.user.username,
                                datetime_list: d,
                                datetime_log: d
                            }).then(() => {
                                this.$alert("List Updated", "Success", "success");
                            });
                        });
                    } else {
                        this.$alert("Write a location", "Empty Field", "warning");
                    }
                }
            },
            update_list_location(location, index) {
                if(!nullValues.includes(this.dsl_list.location.join(","))){
                    this.dsl_list.location = this.dsl_list.location.filter(obj => obj.length > 0);
                    if(this.dsl_list.location.filter(obj => obj == location).length > 1){
                        this.$alert("Duplicate location", "Warning", "warning");
                        this.dsl_list.location[index] = "";
                    } else {
                        var d = new Date()
                        d.setHours(d.getHours() + 3);
                        this.$http.patch("/daily_staff_list/updateList/" + this.dsl_list.dsl_id, {
                            location: this.dsl_list.location.join(","),
                            note: this.dsl_list.note ||  null,
                            datetime_list: d
                        }).then(() => { 
                            var emps_differ_location = [];
                            this.employees.forEach(element => {
                                if(!this.dsl_list.location.includes(element.location)){
                                    emps_differ_location.push(element.at_id);
                                }
                            });
                            if(emps_differ_location.length > 0){
                                this.$http.patch("/attendance/updateAttendancesLocation", {
                                    emps: emps_differ_location,
                                    location,
                                    datetime_log: d,
                                    user: this.user.username,
                                    dsl_id: this.dsl_list.dsl_id,
                                    st_id: this.dsl_list.st_id
                                }).then(() => {
                                    emps_differ_location.forEach(at_id => {
                                        let index = this.employees.findIndex(obj => obj.at_id == at_id);
                                        this.employees[index].location = location;
                                    });
                                });
                            }
                         });
                    }
                } else {
                    this.$alert("Enter location", "Empty location", "warning");
                }
            },
            delete_location(location) {
                if(this.employees.filter(obj => obj.location == location).length > 0){
                    this.$alert("One of the employees in this location", "You can't delete this location", "error");
                } else {
                    this.dsl_list.location = this.dsl_list.location.filter(obj => obj != location);
                    this.update_list_location(location, 0);
                }
            },
            update_attendance() {
                if(this.single_employee.at_id != null){
                    if(this.single_employee.fine < 0){
                        this.$alert("You can't set this fine", "Warning", "warning");
                    } else {
                        document.getElementById("btn_update_attendance").setAttribute("disabled", "disabled");
                        var d = new Date()
                        d.setHours(d.getHours() + 3);
                        this.$http.patch("/attendance/updateAttendance/" + this.single_employee.at_id, {
                            fine: this.single_employee.fine,
                            fine_reason: this.single_employee.fine_reason,
                            expense: this.single_employee.expense,
                            expense_reason: this.single_employee.expense_reason,
                            transport: this.single_employee.transport,
                            transport_reason: this.single_employee.transport_reason,
                            food: this.single_employee.food,
                            food_reason: this.single_employee.food_reason,
                            loan: this.single_employee.loan,
                            loan_reason: this.single_employee.loan_reason,
                            accomodation: this.single_employee.accomodation,
                            accomodation_reason: this.single_employee.accomodation_reason,
                            user: this.user.username,
                            datetime_log: d,
                            dsl_id: this.dsl_list.dsl_id,
                            st_id: this.dsl_list.st_id,
                            employee: this.employees.find(obj => obj.at_id == this.single_employee.at_id).full_name,
                        }).then(() => {
                            this.$alert("Update Successfull", "Success", "success");
                            let index = this.employees.findIndex(obj => obj.at_id == this.single_employee.at_id);
                            this.employees[index].fine_reason = this.single_employee.fine_reason;
                            this.employees[index].fine = this.single_employee.fine;
                            this.employees[index].expense_reason = this.single_employee.expense_reason;
                            this.employees[index].expense = this.single_employee.expense;
                            this.employees[index].transport_reason = this.single_employee.transport_reason;
                            this.employees[index].transport = this.single_employee.transport;
                            this.employees[index].food_reason = this.single_employee.food_reason;
                            this.employees[index].food = this.single_employee.food;
                            this.employees[index].loan_reason = this.single_employee.loan_reason;
                            this.employees[index].loan = this.single_employee.loan;
                            this.employees[index].accomodation_reason = this.single_employee.accomodation_reason;
                            this.employees[index].accomodation = this.single_employee.accomodation;
                        }).catch((err) => {
                            showError(err);
                        }).finally(() => {
                            document.getElementById("btn_update_attendance").removeAttribute("disabled");
                        });
                    }
                }
            },
            validateNumber(evt) {
                var theEvent = evt || window.event;

                // Handle paste
                if (theEvent.type === 'paste') {
                    key = event.clipboardData.getData('text/plain');
                } else {
                // Handle key press
                    var key = theEvent.keyCode || theEvent.which;
                    key = String.fromCharCode(key);
                }
                var regex = /[0-9]|\./;
                if( !regex.test(key) ) {
                    theEvent.returnValue = false;
                    if(theEvent.preventDefault) theEvent.preventDefault();
                }
            },
            setWork(work, at_id) {
                if(this.is_edit){
                    var d = new Date()
                    d.setHours(d.getHours() + 3)
                    this.$http.patch("/attendance/setWork/" + at_id, {
                        work,
                        dsl_id: this.dsl_list.dsl_id,
                        user: this.user.username,
                        employee: this.employees.find(obj => obj.at_id == at_id).full_name,
                        datetime_log: d,
                        st_id: this.dsl_list.st_id,
                        datetime_list: d
                    }).then(() => {})
                    .catch((err) => { showError(err); });
                }

            }
        },
        watch: {
            'dsl_list.work_date': function(value){
                if(!nullValues.includes(value) &&  !nullValues.includes(this.dsl_list.st_id)){
                    this.get_list(this.dsl_list.st_id, value);
                    this.get_missed_employees();
                }
            },
            'dsl_list.st_id': function(value){
                if(!nullValues.includes(value) && !nullValues.includes(this.dsl_list.work_date)){
                    this.get_list(value, this.dsl_list.work_date);
                    this.get_missed_employees();
                }
            }
        },
        computed: {
            is_edit(){
                var date1 = new Date(this.dsl_list.work_date);
                var date2 = new Date();
                var difference = date1.getTime() - date2.getTime();
                var days = Math.ceil(difference / (1000 * 3600 * 24));
                if(days <= -3 && this.user.role == 'E'){
                    return false;
                }
                return true;
            },
            filtered_other_staffs() {
                return this.other.staffs.filter(obj => obj.st_id != this.dsl_list.st_id);
            },
            startDate() {
                const date = new Date();
                const year = date.getFullYear();
                const month = date.getMonth();
                return new Date(year, month, 2).toISOString().split('T')[0];
            },
            endDate() {
                const date = new Date();
                const year = date.getFullYear();
                const month = date.getMonth();
                return new Date(year, month + 1, 1).toISOString().split('T')[0];
            },
            isFriday() {
                const date = new Date(this.dsl_list.work_date);
                return date.getDay() == 5;
            }
        }
    }
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .rtl {
        text-align: right !important;
    }
</style>