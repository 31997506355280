<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-xl-6">
                <div class="card shadow-lg o-hidden border-0 my-5 animate__animated animate__fadeInDown">
                    <div class="card-body p-0">
                        <div class="p-5">
                            <div class="text-center">
                                <h4 class="text-dark mb-4">
                                    Ideal Power Company
                                </h4>
                            </div>
                            <div class="form-group"><input class="form-control form-control-user" v-model="username" type="text" id="exampleInputEmail" placeholder="Enter Phone Number..." name="email"></div>
                            <div class="form-group"><input class="form-control form-control-user" v-model="password" v-on:keyup.enter="login()" type="password" id="exampleInputPassword" placeholder="Password" name="password"> </div>
                            <button class="btn btn-success btn-block text-white btn-user" id="btn_login" v-bind:disabled="username.length == 0 || password.length < 6" v-on:click="login()">Login</button>
                            <hr>
                            <!-- <div class="text-center">
                                Developed by: &nbsp;
                                <a href="" target="_blank">  </a> &nbsp; -  &nbsp;
                                <a href="tel:">  </a>
                            </div> -->
                            <div :class="`mt-8 alert alert-${(calculateExpireDate) > 5 ? 'warning' : 'danger'} text-center`" v-if="calculateExpireDate <= 14">
                                <i class="fa fa-exclamation-circle mx-2"></i> Warning: The System will Expires within {{ calculateExpireDate }} Days
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import showError from '../showError';
    export default {
        name: "Login",
        data() {
            return {
                username: "",
                password: "",
                date: '2024-11-19'
            }
        },
        beforeCreate() {
            this.$http.post("/isLogged").then(() => window.location.href = '/');
        },
        methods: {
            login() {
                document.getElementById("btn_login").setAttribute("disabled", "disabled");
                this.$http.post("/login", {
                    username: this.username,
                    password: this.password
                }).then(({data}) => {
                    if(data == true){
                        window.location.href = "/";
                    }
                }).catch((err) => {
                    showError(err);
                }).finally(() => {
                    document.getElementById("btn_login").removeAttribute("disabled");
                });
            }
        },
        computed: {
            calculateExpireDate() {
                let date1 = new Date(this.date);
                let date2 = new Date();
                const diff = date1.getTime() - date2.getTime();
                let TotalDays = Math.ceil(diff / (1000 * 3600 * 24));
                return TotalDays;
            }
        }
    }
</script>